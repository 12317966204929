.item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
    &:hover {
        .item__link {
            cursor: pointer;
            text-decoration: underline;
            color: #92489b;
            .item__img {
                filter: inherit;
            }
        }
    }
    &__link {
        display: block;
        width: 100%;
        color: $color-text;
        text-decoration: none;
    }
    &__img {
        height: 104px;
        margin-bottom: 30px;
        filter: grayscale(1) brightness(0.8);
    }
    &__heading {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            word-break: break-all;
        }
        @include media-breakpoint-up(md) {
            font-size: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: $h3-size;
            height: 130px;
        }
    }
    &__content {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            word-break: break-all;
        }
        @include media-breakpoint-up(md) {
            font-size: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: $h3-size;
        }
    }
}