.header {
    font-size: $record-size;
    line-height: 1;
    padding: 18px 0;
    display: flex;
    @include media-breakpoint-up(xs) {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        line-height: 1.2;
    }
    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
    &__address {
        @include media-breakpoint-up(xs) {
            padding: 0 15px 15px;
        }
        @include media-breakpoint-up(sm) {
            padding: 0;
        }
        @include media-breakpoint-up(md) {
            padding-left: 35px;
        }
    }
    &__logo {
        text-align: center;
        @include media-breakpoint-up(xs) {
            padding: 0 15px 15px;
        }
        @include media-breakpoint-up(sm) {
            padding: 0 15px;
        }
    }
    &__img {
        max-width: 197px
    }
    &__note {
        display: block;
        font-size: 12.5px;
        padding-top: 9px;
    }
    &__contacts {
        @include media-breakpoint-up(xs) {
            padding: 0 15px;
        }
        @include media-breakpoint-up(sm) {
            padding: 0;
        }
        @include media-breakpoint-up(md) {
            padding-right: 35px;
        }
    }
    &__link {
        color: $color-text;
        text-decoration: none;
        &:hover {
            text-decoration: underline
        }
    }
    &__accent {
        font-family: $font-family-accent;
    }
}