.filter {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;

    &__item {
        margin-bottom: 10px;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background: $button;
            .filter__link {
                color: #fff;
            }
        }
    }

    &__link {
        color: $button;
        line-height: 1;
        padding: 5px;
    }
}

.filter__item--active {
    background: $button;
    .filter__link {
        color: #fff;
        cursor: default;
    }
}