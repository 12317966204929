.comfort {
    @include media-breakpoint-up(xs) {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
        flex-grow: 1;
    }
    &__img {
        max-width: 60px;
        margin-right: 20px;
    }
}