.photo-album {
    margin: 0 3px 6px 3px;
    position: relative;
    min-width: 200px;
    overflow: hidden;
    
    @include media-breakpoint-up(xs) {
        width: ((1110px + 6px) / 4 - 6px);
    }

    @include media-breakpoint-up(sm) {
        width: ((510px + 6px) / 2 - 6px);
    }

    @include media-breakpoint-up(md) {
        width: ((690px + 6px) / 3 - 6px);
    }

    @include media-breakpoint-up(lg) {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            //width: 100%;
        }
        width: ((930px + 6px) / 4 - 6px);
    }

    @include media-breakpoint-up(xl) {
        width: ((1110px + 6px) / 4 - 6px);
    }

    &__link {
        display: block;
        position: relative;
        text-decoration: none;
        &:hover {
            .photo-album__img {
                transform: scale(1.2);
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &__img {
        display: block;
        position: relative;
        overflow: hidden;
        height: 220px;
        transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);

        &::after {
            opacity: 0;
            background-color: rgba(0, 0, 0, .6);
            transition: opacity 1.5s cubic-bezier(.19, 1, .22, 1);
            display: block;
            height: 100%;
            z-index: 2;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            padding: 0 3px 6px 3px;
        }

        /* img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%,0);
        } */
    }

    &__heading {
        position: absolute;
        bottom: 0;
        background: #333;
        width: 100%;
        text-align: center;
        color: #fff;
        padding: 15px 10px;
        font-size: 17px;
        line-height: 1;
        z-index: 5;
    }
}