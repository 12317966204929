.form {
    &__control {
        display: block;
        width: 100%;
        padding: .5rem .75rem;
        font-size: 1rem;
        line-height: 1.25;
        color: #464a4c;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: .25rem;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
       
        &--input {
            margin-bottom: 10px;
            @include media-breakpoint-up(md) {
                &:last-child() {
                    margin-bottom: 0;
                }
            }
        }
        &--textarea {
            height: 119px;
            margin-bottom: 20px;
        }
    }
    &__error, &__error::placeholder {
        color: $color-secondary;
    }
}

.form__alert--error {
    margin-bottom: 10px;
    background: #ffe3e3;
    padding: 5px 10px;
}

.form__alert--success {
    margin-bottom: 10px;
    background: #e6ffe3;
    padding: 5px 10px;
}

span.form__error {
    display: block;
    margin: 2px 0;
    font-size: 16px;
    font-weight: bold;
}

span.form__success {
    display: block;
    color: #21951e;
    margin: 2px 0;
    font-size: 16px;
    font-weight: bold;
}