.wrapper-img--texture {
    background: url(../img/texture.png) 0 repeat;
    margin: 0;
    @include media-breakpoint-up(xs) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.wrapper-img--offer {
    background: url(../img/offer.png) center no-repeat;
    padding: 102px 0;
}