.gallery {
    margin: 0 3px 6px 3px;
    position: relative;
    min-width: 200px;
    overflow: hidden;

    @include media-breakpoint-up(xs) {
        max-width: ((1110px + 6px) / 4  - 6px);
    }

    @include media-breakpoint-up(sm) {
        max-width: ((510px + 6px) / 2  - 6px);
    }

    @include media-breakpoint-up(md) {
        max-width: ((690px + 6px) / 3  - 6px);
    }

    @include media-breakpoint-up(lg) {

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
        }

        max-width: ((930px + 6px) / 4  - 6px);
    }

    @include media-breakpoint-up(xl) {
        max-width: ((1110px + 6px) / 4  - 6px);
    }

    &__link {
        &::after {
            opacity: 0;
            background-color: rgba(0, 0, 0, .6);
            transition: opacity 1.5s cubic-bezier(.19, 1, .22, 1);
            display: block;
            height: 100%;
            z-index: 2;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            padding: 0 3px 6px 3px;
        }

        img {
            transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:hover {

            &::after {
                opacity: 1;
            }

            &::before {
                left: 50%;
                position: absolute;
                top: 50%;
                background: url(../img/icons/magnifier.png) no-repeat;
                width: 35px;
                height: 35px;
                margin: -18px 0 0 -18px;
                content: "";
                display: block;
                z-index: 20;
            }

            img {
                transform: scale(1.2);
            }
        }
    }
}