///* ========================================================================
//   #FONTS
//   ======================================================================== */

// Переменные, содержащие font-family для всех подключенных шрифтов
$font-family-scriptorama: 		    'Scriptorama';
$font-family-museo-sans-cyrl-100: 	'Museo Sans Cyrl 100';
$font-family-museo-sans-cyrl-300: 	'Museo Sans Cyrl 300';
$font-family-museo-sans-cyrl-500: 	'Museo Sans Cyrl 500';

// Переменные, содержашие font-family по назначению
$font-family-copy:		    $font-family-museo-sans-cyrl-100, sans-serif;
$font-family-heading: 	    $font-family-scriptorama, sans-serif;
$font-family-subheading: 	$font-family-museo-sans-cyrl-300, sans-serif;
$font-family-accent: 	    $font-family-museo-sans-cyrl-500, sans-serif;

$font-size-default: $default-size;

