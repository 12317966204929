.carousel {
    padding: 0 40px;
    @include media-breakpoint-up(md) {
        padding: 0 20px;
    }
    .slick-slide {
        position: relative;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
        img {
            display: block;
            width: 100%;
        }
        a {
            display: block;
            position: relative;
            overflow: hidden;
            &::after {
                opacity: 0;
                background-color: rgba(0, 0, 0, .6);
                transition: opacity 1.5s cubic-bezier(.19, 1, .22, 1);
                display: block;
                height: 100%;
                z-index: 2;
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
            }
            img {
                transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
                &::before {
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    background: url(../img/icons/magnifier.png) no-repeat;
                    width: 35px;
                    height: 35px;
                    margin: -18px 0 0 -18px;
                    content: "";
                    display: block;
                    z-index: 20;
                }
    
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .slick-arrow {
        width: 30px;
        height: 100%;
    }
    .slick-prev {
        left: -20px;
        top: 0;
        transform: rotate(180deg);
    }
    .slick-next {
        right: -20px;
    }
}