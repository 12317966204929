/* ==========================================================================
   #RESPONSIVE-SPACINGS
   ========================================================================== */

/**
 * Utility classes enhancing the normal spacing classes by adding responsiveness
 * to them. By default this brings us the following classes:
 *
 *   .u-margin-bottom-none@mobile {}
 *   .u-margin-bottom-none@tablet {}
 *   .u-margin-bottom-none@desktop {}
 *   .u-margin-bottom-none@wide {}
 *
 * But we can affect the classes that get generated by altering the appropriate
 * Sass maps below.
 */



// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
//
//   <div class="u-margin-bottom@mobile">
//
// Be aware that since the `@` symbol is a reserved symbol in CSS, it has to be
// escaped with a `\`. In the markup though, you write your classes without the
// backslash (e.g. `u-margin-bottom@mobile`).
$inuit-widths-breakpoint-separator: \@ !default;



$inuit-responsive-spacing-directions: (
  "-bottom": "-bottom",
) !default;

$inuit-responsive-spacing-properties: (
  "margin": "margin",
) !default;

$inuit-responsive-spacing-sizes: (
  "-none": 0
) !default;



/* stylelint-disable max-nesting-depth */

@each $property-namespace, $property in $inuit-responsive-spacing-properties {

  @each $direction-namespace, $direction-rules in $inuit-responsive-spacing-directions {

    @each $size-namespace, $size in $inuit-responsive-spacing-sizes {

      @each $inuit-bp-name, $inuit-bp-value in $mq-breakpoints {

        @include mq($from: $inuit-bp-name) {

          .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}#{$inuit-widths-breakpoint-separator}#{$inuit-bp-name} {

            @each $direction in $direction-rules {
              #{$property}#{$direction}: $size !important;
            }

          }

        }

      }

    }

  }

}

/* stylelint-enable max-nesting-depth */
