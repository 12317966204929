.menu {
    display: none;
    &__list { 
        display: flex;
        list-style: none;
        margin: 0;
        @include media-breakpoint-up(xs) {
            justify-content: space-between;
            flex-direction: column;
        }
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }
    &__item {
        position: relative;
        flex: 1 1 auto;
        text-align: center;
        &:hover {
            cursor: pointer;
            
            .menu__link {
                color: $hover-text;

                 &::before , &::after {
                    content: "";
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    background: url(../img/svg/uzor.svg) top 4px center no-repeat;
                    background-size: 60px;
                }
    
                &::after {
                    transform: rotate(180deg);
                } 
            }
        }
    }
    &__link {
        color: #fff;
        text-decoration: none;
        padding: 14px 5px;
        display: block;
    }
    &--callback {
        background: $button;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $button;
            .menu__link {
                color: #fff;

                &::before , &::after {
                    content: "";
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    background: url(../img/svg/uzor-white.svg) top 4px center no-repeat;
                    background-size: 60px;
                }
            }
        }
    }
    &__img {
        position: absolute;
        filter: brightness(25);
    }
}

.menu__link--active {
    cursor: default;

    &::before , &::after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: url(../img/svg/uzor.svg) top 4px center no-repeat;
        background-size: 60px;
    }

    &::after {
        transform: rotate(180deg);
    }
    
    
    color: $hover-text;
    
}