.price {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        border-top: 1px solid #f4f2ed;
        font-size: 17px;
        font-family: $font-family-copy;
        
        &:nth-child(2n+1) {
            background-color: #fbfbfb;
        }

        &:hover {
            background-color: #faf9f4;
        }

        &:last-child {
            border-bottom: 1px solid #f4f2ed;
        }
    }

    &__name {
        width: 50vw;
        @include media-breakpoint-up(md) {
            width: 85vw;
        }
    }

    &__value {
        color: $color-accent;
        font-family: $font-family-accent;
        width: 50vh;
        text-align: right;
        @include media-breakpoint-up(md) {
            width: 15vw;
        }
    }
}