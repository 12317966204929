.contact {
    @include media-breakpoint-up(xs) {
        width: 320px;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up(lg) {
        width: 290px;
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
        width: 300px;
    }
    &__item {
        display: flex;
        align-items: center;
        color: $color-primary;
        font-family: $font-family-accent;
        
        &:hover {
            color: $button;
            .contact__img {
                filter: none;
            }
            .contact__link, a {
                color: $button;
            }
        }
    }
    &__info {
        p {
            margin-bottom: 0;
        }
        a {
            display: block;
            text-decoration: none;
            color: $color-primary;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__link {
        display: block;
        text-decoration: none;
        color: $color-primary;
        &:hover {
            text-decoration: underline;
        }
    }
    &__img {
        width: 50px;
        margin-right: 20px;
        filter: grayscale(1) brightness(0) contrast(0.6);
        flex: 0 0 50px;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
            height: 50px;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }
}