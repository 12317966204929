.img-offer {
    position: relative;
    &__fon {
        padding: 0;
        background: #6a4876;
        height: 125px;
        margin-top: 70px;
        display: flex;
        align-items: center;
        position: relative;
        @include media-breakpoint-up(xs) {
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 10px;
        }
        @include media-breakpoint-up(sm) {
            margin-left: 75px;
            margin-right: 75px;
        }
    }
    &__zigzag {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            top: 0;
        }
        position: absolute;
        height: 100%;
        filter: brightness(0.97) contrast(1.02);
        img {
            height: 100%;
        }
        &--left {
            left: -16px;
            transform: rotate(180deg);
        }
        &--right {
            right: -16px;
        }
    }
    &__line {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            top: 0;
            height: 100%;
            padding: 8px 0;
        }
        position: absolute;
        width: 2px;
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
    }
    &__jackets {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            bottom: 0;
        }
        position: absolute;
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    &__item {
        position: absolute;
        bottom: 0;
        &--left {
            left: 6vw;
        }
        &--center {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                left: 0;
                width: 100%;
                text-align: center;
            }
            z-index: 1;
            
        }
        &--right {
            right: 5vw;
        }
    }
}