.social-networks {
    display: flex;
    align-items: center;
    justify-content: center;

    &__link {
        padding: 0 5px;
        text-decoration: none;
        filter: grayscale(1) brightness(0) contrast(0.6);

        &:hover {
            filter: none;
        }
    }
}