.u-align-baseline {
  vertical-align: baseline !important; }

.u-align-top {
  vertical-align: top !important; }

.u-align-middle {
  vertical-align: middle !important; }

.u-align-bottom {
  vertical-align: bottom !important; }

.u-align-text-bottom {
  vertical-align: text-bottom !important; }

.u-align-text-top {
  vertical-align: text-top !important; }

.u-bg-primary {
  background-color: #007bff !important; }

a.u-bg-primary:hover, a.u-bg-primary:focus,
button.u-bg-primary:hover,
button.u-bg-primary:focus {
  background-color: #0062cc !important; }

.u-bg-secondary {
  background-color: #6c757d !important; }

a.u-bg-secondary:hover, a.u-bg-secondary:focus,
button.u-bg-secondary:hover,
button.u-bg-secondary:focus {
  background-color: #545b62 !important; }

.u-bg-success {
  background-color: #28a745 !important; }

a.u-bg-success:hover, a.u-bg-success:focus,
button.u-bg-success:hover,
button.u-bg-success:focus {
  background-color: #1e7e34 !important; }

.u-bg-info {
  background-color: #17a2b8 !important; }

a.u-bg-info:hover, a.u-bg-info:focus,
button.u-bg-info:hover,
button.u-bg-info:focus {
  background-color: #117a8b !important; }

.u-bg-warning {
  background-color: #ffc107 !important; }

a.u-bg-warning:hover, a.u-bg-warning:focus,
button.u-bg-warning:hover,
button.u-bg-warning:focus {
  background-color: #d39e00 !important; }

.u-bg-danger {
  background-color: #dc3545 !important; }

a.u-bg-danger:hover, a.u-bg-danger:focus,
button.u-bg-danger:hover,
button.u-bg-danger:focus {
  background-color: #bd2130 !important; }

.u-bg-light {
  background-color: #f8f9fa !important; }

a.u-bg-light:hover, a.u-bg-light:focus,
button.u-bg-light:hover,
button.u-bg-light:focus {
  background-color: #dae0e5 !important; }

.u-bg-dark {
  background-color: #343a40 !important; }

a.u-bg-dark:hover, a.u-bg-dark:focus,
button.u-bg-dark:hover,
button.u-bg-dark:focus {
  background-color: #1d2124 !important; }

.u-bg-white {
  background-color: #fff !important; }

.u-bg-transparent {
  background-color: transparent !important; }

.u-border {
  border: 1px solid #dee2e6 !important; }

.u-border-top {
  border-top: 1px solid #dee2e6 !important; }

.u-border-right {
  border-right: 1px solid #dee2e6 !important; }

.u-border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.u-border-left {
  border-left: 1px solid #dee2e6 !important; }

.u-border-0 {
  border: 0 !important; }

.u-border-top-0 {
  border-top: 0 !important; }

.u-border-right-0 {
  border-right: 0 !important; }

.u-border-bottom-0 {
  border-bottom: 0 !important; }

.u-border-left-0 {
  border-left: 0 !important; }

.u-border-primary {
  border-color: #007bff !important; }

.u-border-secondary {
  border-color: #6c757d !important; }

.u-border-success {
  border-color: #28a745 !important; }

.u-border-info {
  border-color: #17a2b8 !important; }

.u-border-warning {
  border-color: #ffc107 !important; }

.u-border-danger {
  border-color: #dc3545 !important; }

.u-border-light {
  border-color: #f8f9fa !important; }

.u-border-dark {
  border-color: #343a40 !important; }

.u-border-white {
  border-color: #fff !important; }

.u-rounded-sm {
  border-radius: 0.2rem !important; }

.u-rounded {
  border-radius: 0.25rem !important; }

.u-rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.u-rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.u-rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.u-rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.u-rounded-lg {
  border-radius: 0.3rem !important; }

.u-rounded-circle {
  border-radius: 50% !important; }

.u-rounded-pill {
  border-radius: 50rem !important; }

.u-rounded-0 {
  border-radius: 0 !important; }

.u-clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.u-d-none {
  display: none !important; }

.u-d-inline {
  display: inline !important; }

.u-d-inline-block {
  display: inline-block !important; }

.u-d-block {
  display: block !important; }

.u-d-table {
  display: table !important; }

.u-d-table-row {
  display: table-row !important; }

.u-d-table-cell {
  display: table-cell !important; }

.u-d-flex {
  display: flex !important; }

.u-d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .u-d-sm-none {
    display: none !important; }
  .u-d-sm-inline {
    display: inline !important; }
  .u-d-sm-inline-block {
    display: inline-block !important; }
  .u-d-sm-block {
    display: block !important; }
  .u-d-sm-table {
    display: table !important; }
  .u-d-sm-table-row {
    display: table-row !important; }
  .u-d-sm-table-cell {
    display: table-cell !important; }
  .u-d-sm-flex {
    display: flex !important; }
  .u-d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .u-d-md-none {
    display: none !important; }
  .u-d-md-inline {
    display: inline !important; }
  .u-d-md-inline-block {
    display: inline-block !important; }
  .u-d-md-block {
    display: block !important; }
  .u-d-md-table {
    display: table !important; }
  .u-d-md-table-row {
    display: table-row !important; }
  .u-d-md-table-cell {
    display: table-cell !important; }
  .u-d-md-flex {
    display: flex !important; }
  .u-d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .u-d-lg-none {
    display: none !important; }
  .u-d-lg-inline {
    display: inline !important; }
  .u-d-lg-inline-block {
    display: inline-block !important; }
  .u-d-lg-block {
    display: block !important; }
  .u-d-lg-table {
    display: table !important; }
  .u-d-lg-table-row {
    display: table-row !important; }
  .u-d-lg-table-cell {
    display: table-cell !important; }
  .u-d-lg-flex {
    display: flex !important; }
  .u-d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .u-d-xl-none {
    display: none !important; }
  .u-d-xl-inline {
    display: inline !important; }
  .u-d-xl-inline-block {
    display: inline-block !important; }
  .u-d-xl-block {
    display: block !important; }
  .u-d-xl-table {
    display: table !important; }
  .u-d-xl-table-row {
    display: table-row !important; }
  .u-d-xl-table-cell {
    display: table-cell !important; }
  .u-d-xl-flex {
    display: flex !important; }
  .u-d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .u-d-print-none {
    display: none !important; }
  .u-d-print-inline {
    display: inline !important; }
  .u-d-print-inline-block {
    display: inline-block !important; }
  .u-d-print-block {
    display: block !important; }
  .u-d-print-table {
    display: table !important; }
  .u-d-print-table-row {
    display: table-row !important; }
  .u-d-print-table-cell {
    display: table-cell !important; }
  .u-d-print-flex {
    display: flex !important; }
  .u-d-print-inline-flex {
    display: inline-flex !important; } }

.u-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .u-embed-responsive::before {
    display: block;
    content: ""; }
  .u-embed-responsive .u-embed-responsive-item,
  .u-embed-responsive iframe,
  .u-embed-responsive embed,
  .u-embed-responsive object,
  .u-embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.u-embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.u-embed-responsive-16by9::before {
  padding-top: 56.25%; }

.u-embed-responsive-4by3::before {
  padding-top: 75%; }

.u-embed-responsive-1by1::before {
  padding-top: 100%; }

.u-flex-row {
  flex-direction: row !important; }

.u-flex-column {
  flex-direction: column !important; }

.u-flex-row-reverse {
  flex-direction: row-reverse !important; }

.u-flex-column-reverse {
  flex-direction: column-reverse !important; }

.u-flex-wrap {
  flex-wrap: wrap !important; }

.u-flex-nowrap {
  flex-wrap: nowrap !important; }

.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.u-flex-fill {
  flex: 1 1 auto !important; }

.u-flex-grow-0 {
  flex-grow: 0 !important; }

.u-flex-grow-1 {
  flex-grow: 1 !important; }

.u-flex-shrink-0 {
  flex-shrink: 0 !important; }

.u-flex-shrink-1 {
  flex-shrink: 1 !important; }

.u-justify-content-start {
  justify-content: flex-start !important; }

.u-justify-content-end {
  justify-content: flex-end !important; }

.u-justify-content-center {
  justify-content: center !important; }

.u-justify-content-between {
  justify-content: space-between !important; }

.u-justify-content-around {
  justify-content: space-around !important; }

.u-align-items-start {
  align-items: flex-start !important; }

.u-align-items-end {
  align-items: flex-end !important; }

.u-align-items-center {
  align-items: center !important; }

.u-align-items-baseline {
  align-items: baseline !important; }

.u-align-items-stretch {
  align-items: stretch !important; }

.u-align-content-start {
  align-content: flex-start !important; }

.u-align-content-end {
  align-content: flex-end !important; }

.u-align-content-center {
  align-content: center !important; }

.u-align-content-between {
  align-content: space-between !important; }

.u-align-content-around {
  align-content: space-around !important; }

.u-align-content-stretch {
  align-content: stretch !important; }

.u-align-self-auto {
  align-self: auto !important; }

.u-align-self-start {
  align-self: flex-start !important; }

.u-align-self-end {
  align-self: flex-end !important; }

.u-align-self-center {
  align-self: center !important; }

.u-align-self-baseline {
  align-self: baseline !important; }

.u-align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .u-flex-sm-row {
    flex-direction: row !important; }
  .u-flex-sm-column {
    flex-direction: column !important; }
  .u-flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .u-flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .u-flex-sm-wrap {
    flex-wrap: wrap !important; }
  .u-flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .u-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .u-flex-sm-fill {
    flex: 1 1 auto !important; }
  .u-flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .u-flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .u-flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .u-flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .u-justify-content-sm-start {
    justify-content: flex-start !important; }
  .u-justify-content-sm-end {
    justify-content: flex-end !important; }
  .u-justify-content-sm-center {
    justify-content: center !important; }
  .u-justify-content-sm-between {
    justify-content: space-between !important; }
  .u-justify-content-sm-around {
    justify-content: space-around !important; }
  .u-align-items-sm-start {
    align-items: flex-start !important; }
  .u-align-items-sm-end {
    align-items: flex-end !important; }
  .u-align-items-sm-center {
    align-items: center !important; }
  .u-align-items-sm-baseline {
    align-items: baseline !important; }
  .u-align-items-sm-stretch {
    align-items: stretch !important; }
  .u-align-content-sm-start {
    align-content: flex-start !important; }
  .u-align-content-sm-end {
    align-content: flex-end !important; }
  .u-align-content-sm-center {
    align-content: center !important; }
  .u-align-content-sm-between {
    align-content: space-between !important; }
  .u-align-content-sm-around {
    align-content: space-around !important; }
  .u-align-content-sm-stretch {
    align-content: stretch !important; }
  .u-align-self-sm-auto {
    align-self: auto !important; }
  .u-align-self-sm-start {
    align-self: flex-start !important; }
  .u-align-self-sm-end {
    align-self: flex-end !important; }
  .u-align-self-sm-center {
    align-self: center !important; }
  .u-align-self-sm-baseline {
    align-self: baseline !important; }
  .u-align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .u-flex-md-row {
    flex-direction: row !important; }
  .u-flex-md-column {
    flex-direction: column !important; }
  .u-flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .u-flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .u-flex-md-wrap {
    flex-wrap: wrap !important; }
  .u-flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .u-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .u-flex-md-fill {
    flex: 1 1 auto !important; }
  .u-flex-md-grow-0 {
    flex-grow: 0 !important; }
  .u-flex-md-grow-1 {
    flex-grow: 1 !important; }
  .u-flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .u-flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .u-justify-content-md-start {
    justify-content: flex-start !important; }
  .u-justify-content-md-end {
    justify-content: flex-end !important; }
  .u-justify-content-md-center {
    justify-content: center !important; }
  .u-justify-content-md-between {
    justify-content: space-between !important; }
  .u-justify-content-md-around {
    justify-content: space-around !important; }
  .u-align-items-md-start {
    align-items: flex-start !important; }
  .u-align-items-md-end {
    align-items: flex-end !important; }
  .u-align-items-md-center {
    align-items: center !important; }
  .u-align-items-md-baseline {
    align-items: baseline !important; }
  .u-align-items-md-stretch {
    align-items: stretch !important; }
  .u-align-content-md-start {
    align-content: flex-start !important; }
  .u-align-content-md-end {
    align-content: flex-end !important; }
  .u-align-content-md-center {
    align-content: center !important; }
  .u-align-content-md-between {
    align-content: space-between !important; }
  .u-align-content-md-around {
    align-content: space-around !important; }
  .u-align-content-md-stretch {
    align-content: stretch !important; }
  .u-align-self-md-auto {
    align-self: auto !important; }
  .u-align-self-md-start {
    align-self: flex-start !important; }
  .u-align-self-md-end {
    align-self: flex-end !important; }
  .u-align-self-md-center {
    align-self: center !important; }
  .u-align-self-md-baseline {
    align-self: baseline !important; }
  .u-align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .u-flex-lg-row {
    flex-direction: row !important; }
  .u-flex-lg-column {
    flex-direction: column !important; }
  .u-flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .u-flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .u-flex-lg-wrap {
    flex-wrap: wrap !important; }
  .u-flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .u-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .u-flex-lg-fill {
    flex: 1 1 auto !important; }
  .u-flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .u-flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .u-flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .u-flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .u-justify-content-lg-start {
    justify-content: flex-start !important; }
  .u-justify-content-lg-end {
    justify-content: flex-end !important; }
  .u-justify-content-lg-center {
    justify-content: center !important; }
  .u-justify-content-lg-between {
    justify-content: space-between !important; }
  .u-justify-content-lg-around {
    justify-content: space-around !important; }
  .u-align-items-lg-start {
    align-items: flex-start !important; }
  .u-align-items-lg-end {
    align-items: flex-end !important; }
  .u-align-items-lg-center {
    align-items: center !important; }
  .u-align-items-lg-baseline {
    align-items: baseline !important; }
  .u-align-items-lg-stretch {
    align-items: stretch !important; }
  .u-align-content-lg-start {
    align-content: flex-start !important; }
  .u-align-content-lg-end {
    align-content: flex-end !important; }
  .u-align-content-lg-center {
    align-content: center !important; }
  .u-align-content-lg-between {
    align-content: space-between !important; }
  .u-align-content-lg-around {
    align-content: space-around !important; }
  .u-align-content-lg-stretch {
    align-content: stretch !important; }
  .u-align-self-lg-auto {
    align-self: auto !important; }
  .u-align-self-lg-start {
    align-self: flex-start !important; }
  .u-align-self-lg-end {
    align-self: flex-end !important; }
  .u-align-self-lg-center {
    align-self: center !important; }
  .u-align-self-lg-baseline {
    align-self: baseline !important; }
  .u-align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .u-flex-xl-row {
    flex-direction: row !important; }
  .u-flex-xl-column {
    flex-direction: column !important; }
  .u-flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .u-flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .u-flex-xl-wrap {
    flex-wrap: wrap !important; }
  .u-flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .u-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .u-flex-xl-fill {
    flex: 1 1 auto !important; }
  .u-flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .u-flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .u-flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .u-flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .u-justify-content-xl-start {
    justify-content: flex-start !important; }
  .u-justify-content-xl-end {
    justify-content: flex-end !important; }
  .u-justify-content-xl-center {
    justify-content: center !important; }
  .u-justify-content-xl-between {
    justify-content: space-between !important; }
  .u-justify-content-xl-around {
    justify-content: space-around !important; }
  .u-align-items-xl-start {
    align-items: flex-start !important; }
  .u-align-items-xl-end {
    align-items: flex-end !important; }
  .u-align-items-xl-center {
    align-items: center !important; }
  .u-align-items-xl-baseline {
    align-items: baseline !important; }
  .u-align-items-xl-stretch {
    align-items: stretch !important; }
  .u-align-content-xl-start {
    align-content: flex-start !important; }
  .u-align-content-xl-end {
    align-content: flex-end !important; }
  .u-align-content-xl-center {
    align-content: center !important; }
  .u-align-content-xl-between {
    align-content: space-between !important; }
  .u-align-content-xl-around {
    align-content: space-around !important; }
  .u-align-content-xl-stretch {
    align-content: stretch !important; }
  .u-align-self-xl-auto {
    align-self: auto !important; }
  .u-align-self-xl-start {
    align-self: flex-start !important; }
  .u-align-self-xl-end {
    align-self: flex-end !important; }
  .u-align-self-xl-center {
    align-self: center !important; }
  .u-align-self-xl-baseline {
    align-self: baseline !important; }
  .u-align-self-xl-stretch {
    align-self: stretch !important; } }

.u-float-left {
  float: left !important; }

.u-float-right {
  float: right !important; }

.u-float-none {
  float: none !important; }

@media (min-width: 576px) {
  .u-float-sm-left {
    float: left !important; }
  .u-float-sm-right {
    float: right !important; }
  .u-float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .u-float-md-left {
    float: left !important; }
  .u-float-md-right {
    float: right !important; }
  .u-float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .u-float-lg-left {
    float: left !important; }
  .u-float-lg-right {
    float: right !important; }
  .u-float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .u-float-xl-left {
    float: left !important; }
  .u-float-xl-right {
    float: right !important; }
  .u-float-xl-none {
    float: none !important; } }

.u-position-static {
  position: static !important; }

.u-position-relative {
  position: relative !important; }

.u-position-absolute {
  position: absolute !important; }

.u-position-fixed {
  position: fixed !important; }

.u-position-sticky {
  position: sticky !important; }

.u-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.u-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .u-sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.u-sr-only-focusable:active, .u-sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.u-w-25 {
  width: 25% !important; }

.u-w-50 {
  width: 50% !important; }

.u-w-75 {
  width: 75% !important; }

.u-w-100 {
  width: 100% !important; }

.u-w-auto {
  width: auto !important; }

.u-h-25 {
  height: 25% !important; }

.u-h-50 {
  height: 50% !important; }

.u-h-75 {
  height: 75% !important; }

.u-h-100 {
  height: 100% !important; }

.u-h-auto {
  height: auto !important; }

.u-mw-100 {
  max-width: 100% !important; }

.u-mh-100 {
  max-height: 100% !important; }

.u-min-vw-100 {
  min-width: 100vw !important; }

.u-min-vh-100 {
  min-height: 100vh !important; }

.u-vw-100 {
  width: 100vw !important; }

.u-vh-100 {
  height: 100vh !important; }

.u-text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.u-text-justify {
  text-align: justify !important; }

.u-text-wrap {
  white-space: normal !important; }

.u-text-nowrap {
  white-space: nowrap !important; }

.u-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-text-left {
  text-align: left !important; }

.u-text-right {
  text-align: right !important; }

.u-text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .u-text-sm-left {
    text-align: left !important; }
  .u-text-sm-right {
    text-align: right !important; }
  .u-text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .u-text-md-left {
    text-align: left !important; }
  .u-text-md-right {
    text-align: right !important; }
  .u-text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .u-text-lg-left {
    text-align: left !important; }
  .u-text-lg-right {
    text-align: right !important; }
  .u-text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .u-text-xl-left {
    text-align: left !important; }
  .u-text-xl-right {
    text-align: right !important; }
  .u-text-xl-center {
    text-align: center !important; } }

.u-text-lowercase {
  text-transform: lowercase !important; }

.u-text-uppercase {
  text-transform: uppercase !important; }

.u-text-capitalize {
  text-transform: capitalize !important; }

.u-font-weight-light {
  font-weight: 300 !important; }

.u-font-weight-lighter {
  font-weight: lighter !important; }

.u-font-weight-normal {
  font-weight: 400 !important; }

.u-font-weight-bold {
  font-weight: 700 !important; }

.u-font-weight-bolder {
  font-weight: bolder !important; }

.u-font-italic {
  font-style: italic !important; }

.u-text-white {
  color: #fff !important; }

.u-text-primary {
  color: #007bff !important; }

a.u-text-primary:hover, a.u-text-primary:focus {
  color: #0056b3 !important; }

.u-text-secondary {
  color: #6c757d !important; }

a.u-text-secondary:hover, a.u-text-secondary:focus {
  color: #494f54 !important; }

.u-text-success {
  color: #28a745 !important; }

a.u-text-success:hover, a.u-text-success:focus {
  color: #19692c !important; }

.u-text-info {
  color: #17a2b8 !important; }

a.u-text-info:hover, a.u-text-info:focus {
  color: #0f6674 !important; }

.u-text-warning {
  color: #ffc107 !important; }

a.u-text-warning:hover, a.u-text-warning:focus {
  color: #ba8b00 !important; }

.u-text-danger {
  color: #dc3545 !important; }

a.u-text-danger:hover, a.u-text-danger:focus {
  color: #a71d2a !important; }

.u-text-light {
  color: #f8f9fa !important; }

a.u-text-light:hover, a.u-text-light:focus {
  color: #cbd3da !important; }

.u-text-dark {
  color: #343a40 !important; }

a.u-text-dark:hover, a.u-text-dark:focus {
  color: #121416 !important; }

.u-text-body {
  color: #212529 !important; }

.u-text-muted {
  color: #6c757d !important; }

.u-text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.u-text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.u-text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.u-text-decoration-none {
  text-decoration: none !important; }

.u-text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.u-text-reset {
  color: inherit !important; }

.u-visible {
  visibility: visible !important; }

.u-invisible {
  visibility: hidden !important; }
