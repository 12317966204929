.dotted-line {
    background: url(../img/svg/line-texture.svg) center bottom 5px repeat-x;
    background-position-x: 6px;
    background-size: auto 2px;
    min-height: 2px;
    position: relative;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: url(../img/svg/line-texture-ie.svg) center bottom 5px repeat-x;
        background-position-x: 6px;
        background-size: 58px 2px;
    }
    &--heading {
        background: url(../img/texture.png) 0 repeat;
        //padding: 0 5.4%;
        padding: 0 4vw;
    }
    &--position {
        background-position: bottom;
    }
    &--grey {
        filter: grayscale(100%) brightness(1.6);
    }
}