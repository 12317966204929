///* ========================================================================
//   #COLORS
//   ======================================================================== */

$color-text: #000000;
$color-primary: $color-text;
$color-secondary: #bd2d28;

$hover-text: #b877d6;
$fon-block: #60416b;
$color-heading: #6a4876;
$color-accent: #895d99;
$button: #92489b;