/* ==========================================================================
   #SECTION
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.section {
    @include clearfix; // padding: 0 10px;
    font-family: $font-family-copy;
    color: $color-text;
}

.section--header {}

.section--menu {
    background: $color-text;
    margin-bottom: 4px;
}

.section--billboard {
    background: url(../img/billboard.png) center -165px no-repeat;
    @include media-breakpoint-up(xs) {
        background-position-x: -640px;
        background-position-y: -90px;
    }
    @include media-breakpoint-up(sm) {
        background-position-x: -530px;
    }
    @include media-breakpoint-up(md) {
        background-position-x: -440px;
    }
    @include media-breakpoint-up(lg) {
        background-position-x: -300px;
    }
    @include media-breakpoint-up(xl) {
        background-position-x: center;
    }
}

.section--services,
.section--sewing {
    background: url(../img/texture.png) 0 repeat;
}

.section--advantages {
    background: $fon-block;
    color: #fff;
    padding: 20px 0
}

.section--footer {
    background: #e6e6e6;
    padding: 20px 0
}

.section--offer {
    h2 {
        @extend .headings-h2;
        margin-bottom: 65px !important;
    }
    h3 {
        @extend .headings-h3;
        @extend .accent__uzor;
        @extend .accent__h3;
        text-align: center;
    }
    p {
        text-align: center;
    }
}