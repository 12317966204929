.headings-h2 {
    font-family: $font-family-heading;
    text-align: center;
    margin-bottom: 50px;
    line-height: 1;
    @include media-breakpoint-up(xs) {
        font-size: 35px;
    }
    @include media-breakpoint-up(sm) {
        font-size: $h2-size;
    }
}

h1 {
    @extend .headings-h2
}

.headings-h3 {
    font-family: $font-family-subheading;
    font-weight: 300 !important;
    line-height: 1.2;
    @include media-breakpoint-up(xs) {
        font-size: 25px;
    }
    @include media-breakpoint-up(sm) {
        font-size: $h3-size;
    }
}