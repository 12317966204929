///* ========================================================================
//   #FONT-FACES
//   ======================================================================== */

@font-face {
    font-family: 'Scriptorama';
    src: url('../fonts/subset-Scriptorama.eot');
    src: url('../fonts/subset-Scriptorama.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Scriptorama.woff2') format('woff2'),
        url('../fonts/subset-Scriptorama.woff') format('woff'),
        url('../fonts/subset-Scriptorama.ttf') format('truetype'),
        url('../fonts/subset-Scriptorama.svg#Scriptorama') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Scriptorama';
    src: url('../fonts/subset-Scriptorama.eot');
    src: url('../fonts/subset-Scriptorama.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Scriptorama.woff2') format('woff2'),
        url('../fonts/subset-Scriptorama.woff') format('woff'),
        url('../fonts/subset-Scriptorama.ttf') format('truetype'),
        url('../fonts/subset-Scriptorama.svg#Scriptorama') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 100';
    src: url('../fonts/subset-MuseoSansCyrl-100.eot');
    src: url('../fonts/subset-MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-100.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-100.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-100.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-100.svg#MuseoSansCyrl-100') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 100 italic';
    src: url('../fonts/subset-MuseoSansCyrl-100Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.svg#MuseoSansCyrl-100Italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl 100';
    src: url('../fonts/subset-MuseoSansCyrl-100.eot');
    src: url('../fonts/subset-MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-100.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-100.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-100.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-100.svg#MuseoSansCyrl-100') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 100 italic';
    src: url('../fonts/subset-MuseoSansCyrl-100Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-100Italic.svg#MuseoSansCyrl-100Italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl 300';
    src: url('../fonts/subset-MuseoSansCyrl-300.eot');
    src: url('../fonts/subset-MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-300.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-300.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-300.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
    font-weight: 300 !important;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 300';
    src: url('../fonts/subset-MuseoSansCyrl-300.eot');
    src: url('../fonts/subset-MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-300.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-300.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-300.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
        font-weight: 300 !important;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 300 italic';
    src: url('../fonts/subset-MuseoSansCyrl-300Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.svg#MuseoSansCyrl-300Italic') format('svg');
        font-weight: 300 !important;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl 300 italic';
    src: url('../fonts/subset-MuseoSansCyrl-300Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-300Italic.svg#MuseoSansCyrl-300Italic') format('svg');
        font-weight: 300 !important;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl 500 Italic';
    src: url('../fonts/subset-MuseoSansCyrl-500.eot');
    src: url('../fonts/subset-MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-500.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-500.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-500.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 500';
    src: url('../fonts/subset-MuseoSansCyrl-500.eot');
    src: url('../fonts/subset-MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-500.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-500.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-500.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 700';
    src: url('../fonts/subset-MuseoSansCyrl-700Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.svg#MuseoSansCyrl-700Italic') format('svg');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'Museo Sans Cyrl 700';
    src: url('../fonts/subset-MuseoSansCyrl-700Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-700Italic.svg#MuseoSansCyrl-700Italic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl 700';
    src: url('../fonts/subset-MuseoSansCyrl-700.eot');
    src: url('../fonts/subset-MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-700.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-700.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-700.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 700';
    src: url('../fonts/subset-MuseoSansCyrl-700.eot');
    src: url('../fonts/subset-MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-700.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-700.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-700.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 900';
    src: url('../fonts/subset-MuseoSansCyrl-900.eot');
    src: url('../fonts/subset-MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-900.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-900.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-900.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 900';
    src: url('../fonts/subset-MuseoSansCyrl-900.eot');
    src: url('../fonts/subset-MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-900.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-900.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-900.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Cyrl 900 italic';
    src: url('../fonts/subset-MuseoSansCyrl-900Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.svg#MuseoSansCyrl-900Italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Cyrl 900';
    src: url('../fonts/subset-MuseoSansCyrl-900Italic.eot');
    src: url('../fonts/subset-MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.woff2') format('woff2'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.woff') format('woff'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.ttf') format('truetype'),
        url('../fonts/subset-MuseoSansCyrl-900Italic.svg#MuseoSansCyrl-900Italic') format('svg');
    font-weight: bold;
    font-style: italic;
}


