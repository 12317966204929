.wrap-row {
    margin-left: -3px;
    margin-right: -3px;
}

.contact-wrap {
    display: flex;
    align-items: center;

    + .user-content {
        margin-top: 24px;
    }

    @media (min-width:400px) {
        padding-left: 18vw;
        padding-right: 18vw;
    }
    @include media-breakpoint-up(lg) {
        justify-content: space-around;
        padding-left: 2vw;
        padding-right: 2vw;
    }
}