.accent {
    margin-bottom: 10vw;
    @include media-breakpoint-up(sm) {
        margin-bottom: 60px;
    }
    &__uzor {
        background: url(../img/svg/uzor.svg) center top no-repeat;
        &--filter {
            filter: grayscale(100%);
            .accent__h3 {
                color: #333 !important;
            }
        }
    }
    &__h3 {
        padding-top: 25px;
        color: $color-heading;
        margin: 0;
        &--filter {
            color: #333 !important;
        }
    }
    &__note {
        display: block;
        text-align: center;
        font-family: $font-family-subheading;
        margin: 0;
    }
}