.user-content {
    // p,
    // ul {
    //     &:last-child {
    //         margin-bottom: 0;
    //     }
    // }


    h2 {
        background: url(../img/svg/uzor.svg) center top no-repeat;
        font-family: $font-family-subheading;
        font-weight: 300 !important;
        line-height: 1.2;
        text-align: center;
        padding-top: 25px;
        color: $color-heading;
        margin: 0;
        padding-top: 25px;
        margin-bottom: 25px;
        margin-top: 50px;
        

        @include media-breakpoint-up(xs) {
            font-size: 25px;
        }
        @include media-breakpoint-up(sm) {
            font-size: $h3-size;
        }
    }
}

.user-content:first-child {
    h2:first-child {
        margin-top: 0;
    }
}